.tworow {
    display: flex;
    flex-wrap: wrap;
}

@media (min-width: 1024px) {
    .tworowinner {
        flex: 0 0 33.33333333%;
        max-width: 33.33333333%;
        padding: 0;
        height: 100px;
        background-color: black;
    }
}



@media (min-width: 768px) and (max-width: 1023px) {
    .tworowinner {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0;
        height: 100px;
        background-color: black;
    }
}


@media (max-width: 767px) {
    .tworowinner {
        padding: 0;
        flex: 0 0 50%;
        max-width: 50%;
        height: 100px;
        background-color: black;
    }
}
