header {
	background: rgb(25 27 50 / 0%);
}

body {
	background: rgb(22 27 50);
	font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: 14px;
    line-height: 1.42857143;
	color: #333;
	-webkit-text-size-adjust: 100%;
	overflow-x: auto;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.bg-blue {
	background-color: #161b32!important;
}

.cont-wide {
	padding-left: 0px;
    padding-right: 0px;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
}

@media (min-width: 1024px) {
  .col-lg-4 {
    flex: 0 0 33.33333333%;
	max-width: 33.33333333%;
	padding: 0px;
  }
}
@media (min-width : 768px) and (max-width : 1023px) {
	.col-lg-4 {
	  flex: 0 0 50%;
	  max-width: 50%;
	  padding: 0px;
	}
}
@media (max-width: 767px) {
	.col-lg-4 {
	/*position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
	width: 100%;*/
	padding: 0px;
	flex: 0 0 100%;
    width: 100%;
	}
}

.wide {
	min-width: 100%;
}

.row-right {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.brewcardmain {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  word-wrap: break-word;
  background-color: #3a272700;
  background-clip: border-box;
  float: right;
  max-height: 1500px;
}

.brewcardheader {
	padding-top: 10px;
	line-height: 30px;
    margin-bottom: 0;
    color: rgb(246 148 140 / 1);
    font-size: 30px;
    font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
	margin-top: 20px;
	text-align: center;
	height: 60px;
}

.brewcard-body {
	border: 1px solid rgb(0 0 0 / 0%);
    /* border-bottom-left-radius: 0.5rem; */
    /* border-bottom-right-radius: 0.5rem; */
    height: 350px;
}

.brewcard-text {
  color: #ffffff;
  padding: 1.5rem 2.75rem;
  white-space: pre-line;
}

.brewcard-bottom {
	margin-bottom: 15px;
	display: flex;
}

.brewcard-infobox {
	flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
    padding: 0px;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
	padding-left: 15px;
	text-align: center;
}

.brewcard-dash {
	display: flex;
}

.dashcard {
	padding-left: 15px;
	padding-right: 15px
}

.dash {
	border-bottom-style: solid;
	border-width: 3px;
}

.border-class {
  border: 1px solid rgb(0 0 0 / 0%);
  border-radius: 1rem
}

.col-lg-4 .mb-4 .right {
  margin-bottom: 1.5rem!important;
  margin-top: 1.5rem!important;
}
/*
.img-brew {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 30%;
  margin-top: 10%;
}


@media (min-width: 1024px) {
	.img-bew {
	  max-width: 30%;
	}
  }
  @media (min-width : 768px) and (max-width : 1023px) {
	  .img-brew {
		max-width: 40%;
	  }
  }
  @media (max-width: 767px) {
	  .img-brew {
		max-width: 50%;
	  }
  }
*/
.carousel-item {
  height: calc( 100vh);
  min-height: 300px;
  background: no-repeat center center scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: rgb(22 27 50);
}

.carousel-inner {
	position: relative;
	width: 100%;
	overflow: hidden;
	height: calc( 100vh);
	background: rgb(25 27 50 / 0%);
}

.info-text {
	font-size: 1.5rem;
	margin-top: 20px;
	margin-bottom: 10px;
}

.info-data {
	font-size: 1rem;
}

.portfolio-item {
  margin-bottom: 30px;
}

.h-test {
  font-family: faster_oneregular
}

@-webkit-keyframes ani-mouse {
	0% {
	opacity: 1;
	top: 29%;
	}
	15% {
	opacity: 1;
	top: 50%;
	}
	50% {
	opacity: 0;
	top: 50%;
	}
	100% {
	opacity: 0;
	top: 29%;
	}
}
@-moz-keyframes ani-mouse {
	0% {
	opacity: 1;
	top: 29%;
	}
	15% {
	opacity: 1;
	top: 50%;
	}
	50% {
	opacity: 0;
	top: 50%;
	}
	100% {
	opacity: 0;
	top: 29%;
	}
}
@keyframes ani-mouse {
	0% {
	opacity: 1;
	top: 29%;
	}
	15% {
	opacity: 1;
	top: 50%;
	}
	50% {
	opacity: 0;
	top: 50%;
	}
	100% {
	opacity: 0;
	top: 29%;
	}
}
@media (min-height: 450px) {
	.scroll-btn {
		display: block;
		position: absolute;
		left: 0;
		right: 0;
	  text-align: center;
	  bottom: 0;
	  color: #9B6E6E
	}	
	.scroll-btn > * {
		display: inline-block;
		line-height: 18px;
		font-size: 13px;
		font-weight: normal;
		color: #7f8c8d;
		color: #ffffff;
		font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
		letter-spacing: 2px;
	}
	.scroll-btn > *:hover,
	.scroll-btn > *:focus,
	.scroll-btn > *.active {
		color: #ffffff;
	}
	.scroll-btn > *:hover,
	.scroll-btn > *:focus,
	.scroll-btn > *:active,
	.scroll-btn > *.active {
		opacity: 0.8;
		filter: alpha(opacity=80);
	}
	.scroll-btn .mouse {
		position: relative;
		display: block;
		width: 35px;
		height: 55px;
		margin: 0 auto 20px;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		border: 3px solid #9B6E6E;
		border-radius: 23px;
	}
	.scroll-btn .mouse > * {
		position: absolute;
		display: block;
		top: 29%;
		left: 50%;
		width: 8px;
		height: 8px;
		margin: -4px 0 0 -4px;
		background: white;
		border-radius: 50%;
		-webkit-animation: ani-mouse 2.5s linear infinite;
		-moz-animation: ani-mouse 2.5s linear infinite;
	animation: ani-mouse 2.5s linear infinite;
	}

	.scroll-btn .scroll-text {
	position: relative;
	display: block;
	color: #9B6E6E
	}

	.scroll-btn .scroll-span {
	background: #9B6E6E
	}
}

@media (max-height: 450px) {
	.scroll-btn .scroll-text {
		position: relative;
		display: none;
		color: #9B6E6E
	}
}
  